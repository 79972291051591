<template>
  <v-app>
    <base-app-bar />

    <base-view />
  </v-app>
</template>

<script>
import BaseAppBar from "./AppBar";
import BaseView from "./View";

export default {
  name: "SimpleLayout",
  components: {
    BaseAppBar,
    BaseView
  },
};
</script>
