<template>
  <v-main class="bg-body">
    <v-fade-transition mode="out-in">
      <v-container class="">
        <router-view />
      </v-container>
    </v-fade-transition>
  </v-main>
</template>

<script>
  export default {
    name: 'SimpleView',
  }
</script>