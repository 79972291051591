<template>
  <v-app-bar app flat color="white" height="80px" scroll-threshold="500">
    <v-container>
      <v-row align="center" style="position: relative;" class="px-4">

        <img
          :src="logo"
          max-height="50"
          height="50"
          @click="() => goToMainPage()"
        />

        <v-spacer></v-spacer>

        <v-tooltip bottom v-if="itemsCount && isAuthenticated && !showCart">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-3" v-bind="attrs" v-on="on" @click.stop="$store.commit('switchCartDrawer')">
              <v-badge :content="itemsCount" bordered>
                <v-icon color="">mdi-cart</v-icon>
              </v-badge>
            </v-btn>
          </template>
          <span>{{ $t("AppBar.toolbar.cart") }} ({{ itemsCount }})</span>
        </v-tooltip>

        <v-menu offset-y v-if="langs.length && langs.length > 0">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon class="mr-3" v-bind="attrs" v-on="on">
              <v-icon>mdi-web</v-icon>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item v-for="(l, index) in langs" :key="index" @click="() => switchLang(l.tblCnfIdioma.codigo)">
              <v-list-item-title class="text-uppercase">{{ l.tblCnfIdioma.codigo }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <!-- BOTONES DE SESION -->
        <v-tooltip bottom v-if="isAuthenticated">
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" @click="() => logout()">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>{{ $t("AppBar.toolbar.logout") }}</span>
        </v-tooltip>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<script>
import { mapState } from 'vuex';
import axios from 'axios';

export default {
  computed: {
    ...mapState({
      items: (state) => state.cart.items,
      isAuthenticated: (state) => state.authentication.isAuthenticated,
    }),
    itemsCount() {
      return this.items.length;
    },
    showCart() {
      return this.$route.path == "/checkout/" + this.$route.params.entidadId;
    },
    logo() {
      const baseUrl = axios.defaults.baseURL;

      const entidadId = this.$route.params.entidadId || 'System';

      return `${baseUrl}/api/v1/entities/${entidadId}/cover`;
    }
  },
  watch: {
    '$route.params.entidadId': {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.fetchLanguages()
        }
      },
    },
  },
  data() {
    return{
      langs: []
    }
  },
  methods: {
    logout() {
      this.$store
        .dispatch("authentication/logout", this.user)
        .then((res) => {

          this.$router.push('/').catch(() => {});
        })
        .catch((err) => {
          this.$console.error(err);
        });
    },
    fetchLanguages() {
      const entidadId = this.$route.params.entidadId;

      axios.get(`api/v1/entities/${entidadId}/langs`)
        .then((res) => {
          const langs = _.get(res, 'data.data', []);

          langs.map((l) => {
            
            if (l.isDefault) {
              const defaultLang = l.tblCnfIdioma.archivo;

              this.switchLang(defaultLang);
            }
          });

          this.langs = langs;
        })
        .catch(() => {
          this.langs = [];
        });
    },
    switchLang(lang) {
      this.$i18n.locale = lang;
    },
    goToMainPage(){
      const entidadId = this.$route.params.entidadId;

      if (entidadId) {
        this.$router.push(`/entities/${entidadId}`).catch(() => {});
      } else {
        this.$router.push('/').catch(() => {});
      }
      
    }
  },
};
</script>